import React, { useRef, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import * as faceapi from "face-api.js";
import Swal from "sweetalert2";

import Button from "../../components/common/Button";
import Loading from "../Loading";
import { faceSearch } from "../../apis/facesearch";
import { useUser } from "../../Store/user";
import { useEvent } from "../../Store/event";
import faceImg from "./faceImg.png";
import faceImg2 from "./faceImg2.png";
import { useCallback } from "react";
import RegisterStatus from "../FaceRec/RegisterStatus";
import BackButton from "../../components/common/Backbutton";
import ShutterSound from "../../assets/audio/ShutterSound.wav";
import Text from "../../components/common/Text";
import { FACE_SEARCH_NO_RESULT, MY_PHOTOS } from "../../constants";
import "./CameraModule.css";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useContext } from "react";
import { ThemeContext } from "../../components/App";

const CAPTURE_FAIL_MESSAGE =
  "Uh Oh! We couldn't verify your face expression, please try again";

const THRESOLD_FOR_LIVELINESS = {
  surprised: 0.1,
  happy: 0.5,
  neutral: 0.5,
};

const SHUTTER_REF_CLASSNAME =
  "w-0 rounded-full bg-black z-50 h-full dark:bg-white";

const ANIMATION_DURATION_MS = 500;

const COUNT_DOWN_TIMER = 10;

// const DETECTION_EXPRESSION = {
//   angry: 0,
//   disgusted: 0,
//   fearful: 0,
//   happy: 0,
//   neutral: 0,
//   sad: 0,
//   surprised: 0,
// };

const LIVELINESS_TEST = [
  {
    name: "happy",
    text: "Make a happy face",
  },
  // {
  //   name: "surprised",
  //   text: "Make a surprised face",
  // },
];
// .map((el) => ({ ...el, sortKey: Math.random() }))
// .sort((a, b) => a.sortKey - b.sortKey);

LIVELINESS_TEST.push({
  name: "neutral",
  text: "Make a neutral face",
});

const Cameramodule = ({ isFullAccess = false, close = () => {} }) => {
  const videoRef = useRef(null);
  const divRef = useRef(null);
  const imgRef = useRef(null);
  const parentImgRef = useRef();
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;
  const canvasRef = useRef();
  const [isCamera, setIsCamera] = useState(true);
  const gStream = useRef(null);
  const [loading, setLoading] = useState(true);
  const [showPreRegSuccess, setShowPreRegSuccess] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [countdown, setCountdown] = useState(COUNT_DOWN_TIMER);

  const livelinessIndicatorArray = useRef([...LIVELINESS_TEST]);
  const [currentExpression, setCurrentExpression] = useState(
    livelinessIndicatorArray.current[0]
  );
  const [activeStep, setCompletedSteps] = useState(0);
  const [startFaceCapture, setStartFaceCapture] = useState(false);
  const [showAuthenticationMessage, setShowAuthenticationMessage] =
    useState(false);
  const [captureFailMessage, setCaptureFailMessage] = useState("");

  const shutterRef = useRef(null);
  const audioRef = useRef(null);
  const countdownAnimationRef = useRef(null);
  const countdownRef = useRef();
  const countdownInterval = useRef();
  const faceDetectionInterval = useRef();

  const theme = useContext(ThemeContext);
  const isDarkMode = theme === "dark";

  const isMobile = window.innerWidth <= 768;

  let dimensions = {
    width: 640,
    height: 480,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if (countdownInterval.current) stopCountDown();
      if (faceDetectionInterval.current) stopFaceDetection();
      stopCamera();
    };
  }, []);

  const startCapture = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: dimensions,
        facingMode: { exact: "environment" },
      })
      .then((stream) => {
        gStream.current = stream;
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (isCamera) {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(
          "https://apps.algomage.com/models/tiny_face_detector_model-weights_manifest.json"
        ),
        //faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        //faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri(
          "https://apps.algomage.com/models/face_expression_model-weights_manifest.json"
        ),
      ])
        .then(() => {
          navigator.mediaDevices
            .getUserMedia({
              video: dimensions,
              facingMode: { exact: "environment" },
            })
            .then((stream) => {
              //   console.log(videoRef.current);
              gStream.current = stream;
              videoRef.current.srcObject = stream;
              videoRef.current.play();
              setLoading(false);
            })
            .catch((err) => {
              console.error("error:", err);
              setLoading(false);
              setIsCamera(false);
            });
        })
        .catch((e) => {
          setLoading(false);
          setIsCamera(false);
          console.log(e);
        });
    }
  }, [videoRef, isCamera]);

  useEffect(async () => {
    const status = await navigator.permissions.query({ name: "camera" });
    status.addEventListener(
      "change",
      (evt) => {
        console.log("evt", evt);
        console.log("evttype", evt.currentTarget.state);
        if (evt.currentTarget.state === "denied") setIsCamera(false);
        else setIsCamera(true);
      },
      { once: true }
    );
  });

  const startCountDown = () => {
    let countdownCopy = COUNT_DOWN_TIMER;
    captureFailMessage && setCaptureFailMessage("");
    countdownInterval.current = setInterval(function () {
      if (--countdownCopy <= 0) {
        resetCapture();
        setCaptureFailMessage(CAPTURE_FAIL_MESSAGE);
        // window.Toast.fire({
        //   icon: "error",
        //   title:
        //     "Uh Oh! We couldn't verify your face expression, please try again",
        // });
        setCountdown(COUNT_DOWN_TIMER);
      }
      if (countdownRef.current) {
        setCountdown(countdownCopy);
      }
    }, 1000);
  };

  const stopCountDown = () => clearInterval(countdownInterval.current);

  const stopFaceDetection = () => clearInterval(faceDetectionInterval.current);

  const resetCapture = () => {
    stopCountDown();
    setStartFaceCapture(false);
    livelinessIndicatorArray.current = [...LIVELINESS_TEST];
    setCurrentExpression(livelinessIndicatorArray.current[0]);
    setCompletedSteps(0);
  };

  useEffect(() => {
    if (startFaceCapture) {
      startCountDown();
      const img = new Image();
      img.src = faceImg;
      let expressionCopy = currentExpression;
      const onVideoPlay = () => {
        faceDetectionInterval.current = setInterval(async () => {
          if (videoRef.current) {
            const detections = await faceapi
              .detectAllFaces(
                videoRef.current,
                new faceapi.TinyFaceDetectorOptions()
              )
              .withFaceExpressions();
            let parentRect = parentImgRef.current.getBoundingClientRect();
            const resizedDetections = parentRect.width
              ? faceapi.resizeResults(detections, {
                  width: parentRect.width,
                  height: parentRect.height,
                })
              : [];
            if (resizedDetections[0]) {
              let width = resizedDetections[0].detection._box._width;
              let x = resizedDetections[0].detection._box._x;
              let rect = imgRef.current?.getBoundingClientRect();
              let parentRect = parentImgRef.current.getBoundingClientRect();
              let imagePosition = { left: rect?.left - parentRect.left };

              if (
                x > imagePosition.left - 50 &&
                x < imagePosition.left + 50 &&
                x + width > rect.width + imagePosition.left - 50 &&
                x + width < rect.width + imagePosition.left + 50
              ) {
                imgRef.current.src = faceImg;

                if (detections.expressions) {
                  if (
                    detections.expressions[expressionCopy.name] >
                    THRESOLD_FOR_LIVELINESS[expressionCopy.name]
                  ) {
                    livelinessIndicatorArray.current.shift(0, 1);
                    setCurrentExpression(livelinessIndicatorArray.current[0]);
                    expressionCopy = livelinessIndicatorArray.current[0];
                    setCompletedSteps((prev) => prev + 1);
                    if (livelinessIndicatorArray.current.length === 0) {
                      setCurrentExpression(null);
                    }
                    audioRef.current.play();
                    if (expressionCopy) {
                      videoRef.current.pause();
                      gStream.current.getTracks().map((track) => {
                        track.stop();
                      });
                    }
                    shutterRef.current.className =
                      shutterRef.current.className + " !w-full z-[100]";
                    countdownAnimationRef.current.style.animation = "none";
                    stopCountDown();
                    setTimeout(() => {
                      shutterRef.current.className = SHUTTER_REF_CLASSNAME;
                      startCapture();
                      setCountdown(COUNT_DOWN_TIMER);
                      countdownAnimationRef.current.style.animation = null;
                      startCountDown();
                    }, ANIMATION_DURATION_MS);
                  }
                }
                if (livelinessIndicatorArray.current.length === 0) {
                  captureImage();
                  setCurrentExpression(null);
                  audioRef.current.play();
                }
                if (Array.isArray(detections)) {
                  if (
                    expressionCopy &&
                    detections[0].expressions[expressionCopy.name] >
                      THRESOLD_FOR_LIVELINESS[expressionCopy.name]
                  ) {
                    livelinessIndicatorArray.current.shift(0, 1);
                    setCurrentExpression(livelinessIndicatorArray.current[0]);
                    expressionCopy = livelinessIndicatorArray.current[0];
                    setCompletedSteps((prev) => prev + 1);
                    if (livelinessIndicatorArray.current.length === 0) {
                      setCurrentExpression(null);
                    }
                    audioRef.current.play();
                    if (expressionCopy) {
                      // videoRef.current.pause();
                      gStream.current.getTracks().map((track) => {
                        track.stop();
                      });
                    }
                    shutterRef.current.className =
                      shutterRef.current.className + " !w-full z-[100]";
                    countdownAnimationRef.current.style.animation = "none";
                    stopCountDown();
                    setTimeout(() => {
                      shutterRef.current.className = SHUTTER_REF_CLASSNAME;
                      if (!expressionCopy) {
                        captureImage();
                      } else {
                        startCapture();
                        setCountdown(COUNT_DOWN_TIMER);
                        countdownAnimationRef.current.style.animation = null;
                        startCountDown();
                      }
                    }, ANIMATION_DURATION_MS);
                  }
                }
                //captureImage()
                return;
              } else {
                if (imgRef.current) imgRef.current.src = faceImg2;
              }
              //canvas.getContext('2d').drawImage(img,x,y,width,height)
            } else {
              if (imgRef.current) imgRef.current.src = faceImg2;
            }
            //faceapi.draw.drawFaceLandmarks(canvas,resizedDetections)
          }
        }, 1000);
      };
      onVideoPlay();
      videoRef.current.addEventListener("play", onVideoPlay);
      videoRef.current.addEventListener("pause", stopFaceDetection);
      return () => {
        clearInterval(faceDetectionInterval.current);
        videoRef.current?.removeEventListener("play", onVideoPlay);
        videoRef.current?.removeEventListener("pause", stopFaceDetection);
      };
    }
  }, [startFaceCapture]);

  const captureImage = useCallback(async () => {
    window.Toast.fire({
      icon: "success",
      title: "Liveness Verified",
    });
    stopCountDown();
    let video = videoRef.current;
    let canvas = canvasRef.current;
    let context = canvas.getContext("2d");
    context.drawImage(video, 0, 0);
    video.pause();
    gStream.current.getTracks().map((track) => {
      track.stop();
    });
    setLoading(true);
    setShowAuthenticationMessage(true);
    let imgBase = canvas.toDataURL("image/jpeg");
    try {
      let result = await faceSearch(
        user.id,
        eventDetails.data.id,
        imgBase,
        eventDetails.data.isPublished,
        user?.previousImages
      );
      //result = {error:false,response:{search_id:'dcdbe86c466c1f612aaba1dce2'}}
      if (result.error) {
        if (isFullAccess) {
          close();
        }
        Swal.fire(FACE_SEARCH_NO_RESULT).then(function (response) {
          //setTimeout(window.location.reload(true));
          setUser({ ...user, id: 0 });
          livelinessIndicatorArray.current = LIVELINESS_TEST;
          setCurrentExpression(livelinessIndicatorArray.current[0]);
          setCompletedSteps(0);
        });
      } else if (result.response.status === 1 && result.response.search_id) {
        //let imgs = await faecResults(result.response.search_id, eventDetails.data.id)
        //console.log("imgs",imgs)
        setSearchId(result.response.search_id);
        resetCapture();
        if (isFullAccess) {
          setUser({ ...user, search_id: result.response.search_id });
          setTimeout(() => {
            close();
            const galleryApp = document.getElementById("photoGalleryContainer");
            galleryApp?.scrollIntoView();
          }, 400);
        }
        //setImages(imgs.response.data)
      } else if (result.response.status === 2) {
        setShowPreRegSuccess(true);
        // setTimeout(() => {
        //   setShowAuthenticationMessage(false);
        //   setLoading(false);
        // }, 2000);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      if (isFullAccess) {
        close();
      }
      Swal.fire(FACE_SEARCH_NO_RESULT).then(function (response) {
        //setTimeout(window.location.reload(true))
        setUser({ ...user, id: 0 });
        livelinessIndicatorArray.current = LIVELINESS_TEST;
        setCurrentExpression(livelinessIndicatorArray.current[0]);
        setCompletedSteps(0);
      });
    }
  }, [user, gStream.current]);

  useEffect(() => {
    const letsChatElement = document.getElementById("haptik-xdk-wrapper");
    if (isMobile) {
      if (letsChatElement) letsChatElement.style = "display:none";
    }
    return () => {
      if (letsChatElement) letsChatElement.style = "";
    };
  }, []);

  const stopCamera = useCallback(() => {
    gStream.current.getTracks().map((track) => {
      track.stop();
    });
    if (isFullAccess) close();
    else {
      setIsCamera(false);
      setStartFaceCapture(false);
    }
  }, [gStream.current]);

  const closeButtonClicked = () => {
    stopCamera();
    if (isFullAccess) {
      close();
    }
    if (!isFullAccess) {
      setUser({ ...user, id: 0 });
    }
  };

  return (
    <div
      className={`${
        isFullAccess ? "" : "bg-light-gray dark:bg-dark-gray"
      } w-full`}
    >
      {loading ? (
        <Loading height={isFullAccess ? "!h-auto" : ""} />
      ) : (
        !showPreRegSuccess &&
        !isMobile && <header className="">{/* <StaticLogo /> */}</header>
      )}
      {searchId ? (
        isFullAccess ? (
          <Navigate to={`/view/${eventDetails.data.slug}/my-photos`} />
        ) : (
          <Navigate
            to={`/facerec/${eventDetails.data.slug}/results/${searchId}`}
          />
        )
      ) : showPreRegSuccess ? (
        <RegisterStatus isFullAccess={isFullAccess} />
      ) : isCamera ? (
        <div
          className={`grid ${loading ? "hidden" : "h-full"} ${
            isFullAccess ? "" : "md:mt-5"
          }`}
        >
          <audio src={ShutterSound} ref={audioRef} />
          <div
            className={`absolute top-[10px] md:right-[30px]  right-[10px] cursor-pointer ${
              isFullAccess ? "md:top-[44px]" : "md:top-[40px]"
            }`}
            onClick={closeButtonClicked}
          >
            <CloseIcon fill={isDarkMode ? "white" : "black"} />
          </div>
          {!startFaceCapture && (
            <div
              className={`text-xl flex-col  text-center mx-auto   flex items-center justify-between ${
                isFullAccess
                  ? "bg-theme-light dark:bg-secondary-800"
                  : "mt-10 bg-light-gray dark:bg-dark-gray"
              }  mb-4 text-dark-gray1 dark:text-light-gray`}
            >
              <div className="uppercase bold text-[18px] md:text-2xl">
                Position your face in the circle
              </div>
              <div className="text-xs md:text-base">
                Press Begin Authentication to start
              </div>
            </div>
          )}
          {startFaceCapture && (
            <div className="flex w-4/5 md:w-2/3 mb-[10px] text-dark-gray1 dark:text-light-gray  mx-auto relative pl-[1.888rem] pr-[2.4rem] mt-[1rem] justify-center items-start">
              <div className="flex flex-col items-end">
                <span
                  className={`rounded-full w-5 h-5 ${
                    activeStep == 0 ? "bg-primary-green" : "bg-primary-red"
                  }`}
                ></span>
                <span className="text-sm absolute -bottom-[10px]"> Step 1</span>
              </div>
              <div
                className={` h-[0.625rem] border-none my-[5px] mb-3 w-4/12 ${
                  activeStep == 0
                    ? "bg-zinc-700 shadow-sidebar"
                    : "bg-primary-red"
                }`}
              ></div>
              <div className="flex flex-col items-end">
                <span
                  className={`rounded-full w-5 h-5 mb-3 ${
                    activeStep == 1
                      ? "bg-primary-green"
                      : activeStep > 1
                      ? "bg-primary-red"
                      : "bg-zinc-700"
                  }`}
                ></span>
                <span className="text-sm absolute -bottom-[10px]"> Step 2</span>
              </div>
              {/* <div
                className={` h-[0.625rem] my-[5px] border-none mb-3 w-4/12 ${
                  activeStep == 1
                    ? "bg-zinc-700 shadow-sidebar"
                    : activeStep == 0
                    ? "bg-zinc-700 shadow-sidebar"
                    : "bg-primary-red"
                }`}
              >
                <div
                  style={{
                    transition: "all 0.5s ease-in-out",
                  }}
                ></div>
              </div> */}
              {/* <div className="flex flex-col items-end">
                <span
                  className={`rounded-full w-5 h-5 mb-3 ${
                    activeStep == 2
                      ? "bg-primary-green"
                      : activeStep == 3
                      ? "bg-primary-red"
                      : "bg-zinc-700"
                  }`}
                ></span>
                <span className="text-sm absolute -bottom-[10px]"> Step 3</span>
              </div> */}
              {/* <div
                className={` h-[0.625rem] border-none mb-3 w-4/12 ${
                  activeStep <= 2
                    ? "bg-zinc-700 shadow-sidebar"
                    : "bg-primary-red"
                }`}
              >
                <div
                  style={{
                    transition: "all 0.5s ease-in-out",
                    width: activeStep == 2 ? progressPercent : "100%",
                  }}
                ></div>
              </div>

              <span
                className={`rounded-full w-5 h-5 mb-3 ${
                  activeStep == 3 ? "bg-primary-green" : "bg-zinc-700"
                }`}
              ></span> */}
            </div>
          )}
          {startFaceCapture && (
            <>
              <p className="text-dark dark:text-white text-center px-10 mb-5 z-100 text-xl font-bold">
                {currentExpression
                  ? currentExpression.text
                  : "Your face will be captured now"}
              </p>
            </>
          )}
          <div
            className={`${
              isFullAccess ? "h-auto" : "min-h-screen"
            }  md:pt-0 relative w-full`}
            ref={divRef}
          >
            <video
              className={`h-[400px] md:h-[480px] mx-auto w-[640px] max-w-full ${
                isFullAccess ? "" : "mt-10"
              }`}
              ref={videoRef}
              muted={true}
              playsInline
              autoPlay={false}
              style={{ transform: "scaleX(-1)" }}
            ></video>

            <div
              id="hollowRound"
              className={`absolute h-[400px] md:h-[480px] md:max-h-[480px] mx-auto w-[640px] max-w-full left-1/2 -translate-x-1/2 top-0  ${
                isFullAccess ? "" : "mt-10"
              }`}
            >
              <div
                className={`w-full h-full  relative overflow-hidden  after:content-[''] after:rounded-full after:shadow-[0_0_0_1000px] ${
                  isFullAccess
                    ? "after:shadow-theme-light dark:after:shadow-secondary-800"
                    : "after:shadow-light-gray dark:after:shadow-dark-gray"
                } after:absolute after:w-[240px] after:h-[240px] md:after:w-[400px] md:after:h-[400px]  after:left-1/2 after:top-1/2  after:-translate-x-1/2 after:-translate-y-1/2`}
              ></div>
            </div>
            <div
              id="shutter"
              className={`h-[400px] md:h-[480px] mx-auto w-[640px] max-w-full flex justify-center items-center absolute left-1/2 -translate-x-1/2 top-0  ${
                isFullAccess ? "" : "mt-10"
              }`}
            >
              <div className="w-[250px] h-[250px] md:w-[400px] md:h-[400px]">
                <div
                  style={{ transition: "all 0.5s ease-in-out" }}
                  ref={shutterRef}
                  className={SHUTTER_REF_CLASSNAME}
                ></div>
              </div>
            </div>

            {!startFaceCapture && (
              <div
                className={`text-xl flex-col  h-[400px] md:h-[480px] py-4 text-center mx-auto w-[640px] opacity-80 flex items-center justify-between max-w-full absolute left-1/2 -translate-x-1/2 top-0 ${
                  isFullAccess
                    ? "bg-theme-light dark:bg-secondary-800"
                    : "mt-10 bg-light-gray dark:bg-dark-gray"
                }  text-dark-gray1 dark:text-light-gray`}
              ></div>
            )}
            {captureFailMessage && !startFaceCapture && (
              <div className="text-sm my-4 text-center text-primary-error-red">
                {captureFailMessage}
              </div>
            )}
            <div
              className={`z-100 ${
                !startFaceCapture
                  ? "pl-[1.3rem] pr-[2.4rem] md:px-0 mx-auto w-4/5 md:w-auto"
                  : ""
              } flex gap-4  justify-center mt-2 md:mt-4 text-center`}
            >
              {/* <BackButton
                onClick={() => {
                  stopCamera();
                  if (!isFullAccess) {
                    setUser({ ...user, id: 0 });
                  }
                }}
                text="Cancel"
                className="mx-3 cursor-pointer w-[90px] md:w-[100px]"
                bg="dark"
              /> */}

              {!startFaceCapture && (
                <Button
                  onClick={() => {
                    setStartFaceCapture(true);
                    // setCompletedSteps((prev) => prev + 1);
                  }}
                  text="Begin Authentication"
                />
              )}
              {startFaceCapture && (
                <div id="countdown" className="relative h-10 w-10 text-center">
                  <div
                    id="countdown-number"
                    ref={countdownRef}
                    className="text-dark-gray leading-10 inline-block dark:text-light-gray"
                  >
                    {countdown}
                  </div>
                  <svg>
                    <circle
                      ref={countdownAnimationRef}
                      r="18"
                      cx="20"
                      cy="20"
                      className="dark:stroke-white stroke-black"
                    ></circle>
                  </svg>
                </div>
              )}
            </div>

            <div
              ref={parentImgRef}
              className={`absolute max-w-full h-[400px] md:h-[480px]  w-[640px] left-0 right-0 mx-auto  ${
                isFullAccess ? "" : "mt-10"
              }  top-0 flex justify-center items-center`}
            >
              {currentExpression?.name &&
                currentExpression?.name !== "neutral" &&
                startFaceCapture && (
                  <div className="absolute top-[80px] md:top-[25px] right-0 md:right-[45px]">
                    <img
                      className="rounded-full w-[100px] md:w-[150px]"
                      src={require(`./face-expressions/${currentExpression?.name}.jpg`)}
                    />
                  </div>
                )}
              {startFaceCapture && (
                <img
                  ref={imgRef}
                  src={faceImg2}
                  className={`max-w-[40%] ${
                    currentExpression?.name !== "neutral" ? "opacity-50" : ""
                  } `}
                />
              )}
            </div>
            <canvas
              ref={canvasRef}
              width={dimensions.width}
              height={dimensions.height}
              className="hidden"
            ></canvas>
          </div>
        </div>
      ) : (
        <div
          className={`${
            loading
              ? "hidden"
              : `${
                  isFullAccess ? "h-[50vh]" : "h-screen w-screen justify-center"
                }  flex  items-center`
          }`}
        >
          <Text className="text-2xl text-dark-gray1 dark:text-light-gray">
            Please allow access to camera to continue
          </Text>
        </div>
      )}
    </div>
  );
};

export default Cameramodule;
