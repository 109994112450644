import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import "./selectedImage.css";
import VideoPlayIcon from "../../../../assets/icons/VideoPlayIcon.jsx";
import ActionsButton from "./components/ActionsButton";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingGif from "../../../../assets/images/loading.gif";
import { useFavourites } from "../../../../Store/favourites";
import DialogWrapper from "../../../../components/common/Dialog";
import Share from "../../../../components/common/Share";
import EmailPopUp from "../../../../components/common/EmailPopUp";
import ProductDetailsModal from "../../../../components/common/ProductDetailsModal";
import { useFolder } from "../../../../Store/folder";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import HeartIcon from "../../../../assets/icons/HeartIcon";
import EyeSlashIcon from "../../../../assets/icons/EyeSlashIcon";
import { printSuccessToast, privacyChangeDisabled } from "../../../../helper";
import {
  EVENT_WHERE_DROPBOX_NEEDED,
  IMAGE_OG_SUBDOMAIN,
  IMAGE_RENDERER_SUBDOMAIN,
  PRINT_IMAGE_SWAL_OBJECT,
} from "../../../../constants";
import { postDropbox, postStoreOrder } from "../../../../apis/cart";
import { useContext } from "react";
import { ThemeContext } from "../../../../components/App";
import HeartOutlineIcon from "../../../../assets/icons/HeartOutlinedIcon";

const cont = {
  position: "relative",
};

const PlaceHolder = ({ width, height }) => {
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ width, height }}
    >
      <img src={LoadingGif} width={50} height={50} />
    </div>
  );
};

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  onClick,
  selected,
  onFavClickHandler,
  privateMode,
  folderId,
  downloadAlertShown = true,
  setDownloadAlertShown = () => {},
}) => {
  const { eventName } = useParams();
  const { user } = useUser();
  const { currentFav, addtoFav } = useFavourites();
  const { setPrivacy } = useFolder();
  const theme = useContext(ThemeContext);

  const [isSelected, setIsSelected] = useState(false);
  const [priMode, setPrivMode] = useState(privateMode);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [sharePopUp, setSharePopUp] = useState(false);
  const [cartPopUp, setCartPopUp] = useState(false);
  const forceRerender = useState()[1];

  const eventDetails = useEvent().response;

  const printPhotoAlert = () =>
    Swal.fire({
      ...PRINT_IMAGE_SWAL_OBJECT(theme, photo.printStoreSrc),
      preConfirm: async () => {
        try {
          await postDropbox(
            photo.printStoreSrc.replace(
              IMAGE_RENDERER_SUBDOMAIN,
              IMAGE_OG_SUBDOMAIN
            )
          );
          const order = await postStoreOrder(
            user.accessToken,
            user.id,
            eventDetails.data.id,
            user.email,
            photo.printStoreSrc.replace(
              IMAGE_RENDERER_SUBDOMAIN,
              IMAGE_OG_SUBDOMAIN
            ),
            photo.key,
            eventDetails.photographer.id
          );
          if (order.status.toString().startsWith("2")) {
            printSuccessToast();
            return;
          } else {
            window.Toast.fire({
              icon: "error",
              title: "Something went wrong",
            });
            return;
          }
        } catch (error) {
          console.log("Error", error);
          window.Toast.fire({
            icon: "error",
            title: "Something went wrong",
          });
          return;
        }
      },
    })
      .then(() => {})
      .catch((error) => {
        console.log("Error in swal while printing photos", error);
      });

  let isFavorite = {};

  useEffect(() => {
    setPrivMode(privateMode);
  }, [privateMode]);

  isFavorite = Array.isArray(photo.originalImageObj?.favouriteList)
    ? photo.originalImageObj.favouriteList.includes(currentFav.id)
    : false;

  if (photo.type === "video") photo.src = photo["data-src"];
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  function openShareBox() {
    setSharePopUp(true);
  }

  const openCartBox = useCallback(() => {
    if (!user.accessToken) {
      setEmailPopUp(true);
    } else {
      if (EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id)) {
        printPhotoAlert();
      } else setCartPopUp(true);
    }
  }, [user]);

  const openEmailBox = async () => {
    if (!user.accessToken) {
      setEmailPopUp(true);
    } else {
      if (isFavorite) {
        const favIndex = photo.originalImageObj.favouriteList.findIndex(
          (el) => el === currentFav.id
        );
        photo.originalImageObj.favouriteList.splice(favIndex, 1);
      } else {
        if (photo.originalImageObj.favouriteList?.length)
          photo.originalImageObj.favouriteList.push(currentFav.id);
        else photo.originalImageObj.favouriteList = [currentFav.id];
      }
      forceRerender(Math.random());
      const res = isFavorite
        ? await addtoFav(photo.key, true, isFavorite?.favouriteId)
        : await addtoFav(photo.key, false, false, photo.originalImageObj);

      if (!res) {
        if (!isFavorite) {
          const favIndex = photo.originalImageObj.favouriteList.findIndex(
            (el) => el === currentFav.id
          );
          photo.originalImageObj.favouriteList.splice(favIndex, 1);
        } else {
          if (photo.originalImageObj.favouriteList?.length)
            photo.originalImageObj.favouriteList.push(currentFav.id);
          else photo.originalImageObj.favouriteList = [currentFav.id];
        }
        forceRerender(Math.random());
      }
    }
  };

  return (
    <div
      onContextMenuCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{
        margin,
        height: photo.height,
        width: photo.width,
        display: "flex",
        flexFlow: "row wrap",
        borderRadius: eventDetails?.data?.isImageRounded ? "10px" : "0px",
        overflow: "hidden",
        cursor: "pointer",
        ...cont,
      }}
      className="relative button button--secondary group"
      data-src={photo["data-src"] ? photo["data-src"] : photo.src}
    >
      <div
        onContextMenuCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        key={index}
      >
        <LazyLoadImage
          height={photo.height}
          onClick={() => {
            onClick(index);
          }}
          placeholder={
            <PlaceHolder width={photo.width} height={photo.height} />
          }
          width={photo.width}
          src={photo.src}
          threshold={0}
        ></LazyLoadImage>

        {photo.type === "video" && (
          <React.Fragment>
            <div className="flex w-full justify-between	items-center absolute sm:p-0 sm:l-5 md:p-1 ">
              <div className="video-tag">Video</div>
            </div>
            <div className="absolute top-1/2 left-1/2 transform pointer-events-none cursor-pointer -translate-x-1/2 -translate-y-1/2">
              <VideoPlayIcon />
            </div>
          </React.Fragment>
        )}
        <div className="group">
          <div className="hidden  group-hover:flex absolute bottom-0 right-0 z-2 w-full">
            {!isSelected && photo.type != "video" && (
              <ActionsButton
                photo={photo}
                privateMode={priMode}
                isFav={isFavorite}
                openEmailBox={openEmailBox}
                openCartBox={openCartBox}
                openShareBox={openShareBox}
                downloadAlertShown={downloadAlertShown}
                setDownloadAlertShown={setDownloadAlertShown}
                folderId={folderId}
              />
            )}
          </div>
          {(Object.keys(isFavorite).length > 0 || !!photo.isPrivate) && (
            <div className="flex group-hover:hidden absolute bottom-0 right-0 z-2 w-auto sm:w-full sm:justify-end items-center sm:h-[48px] pb-[10px] pl-[10px] pr-[10px] sm:pt-[5px] sm:pb-[1.25rem] sm:pl-[5px] sm:pr-[1.25rem]">
              <span
                className={`mr-[10px] sm:mr-5  ${
                  photo.isPrivate ? "block" : "invisible"
                } `}
                onClick={() => {
                  user.isAdmin
                    ? setPrivacy(photo.key, folderId, photo.isPrivate)
                    : privacyChangeDisabled();
                }}
                title={"Mark Photo as Public"}
              >
                <EyeSlashIcon height={"20"} width={"20"} color={"#00b7b8"} />
              </span>
              <span
                className={`${
                  Object.keys(isFavorite).length > 0 ? "block" : "invisible"
                }`}
                onClick={openEmailBox}
                title={`Added to ${currentFav?.name}`}
              >
                <HeartIcon height={"24"} width={"24"} color="white" />
              </span>
            </div>
          )}
        </div>
        {Array.isArray(photo.originalImageObj?.favouriteList) &&
        photo.originalImageObj.favouriteList.includes(currentFav.id) ? (
          <div className="absolute top-3 left-3 w-7 h-7 rounded-full bg-black-gray flex justify-center items-center">
            {" "}
            <HeartOutlineIcon />{" "}
          </div>
        ) : (
          <></>
        )}
      </div>
      <DialogWrapper
        open={sharePopUp}
        close={() => {
          setSharePopUp(false);
        }}
        description=""
        title="Share"
      >
        <Share
          shareUrl={`${window.location.protocol}//${window.location.host}/view/${eventName}/${folderId}/image/${photo.key}`}
        />
      </DialogWrapper>
      <DialogWrapper
        open={emailPopUp}
        close={() => {
          setEmailPopUp(false);
        }}
        description=""
        title="Login"
      >
        <EmailPopUp
          close={(e) => {
            setEmailPopUp(e);
          }}
        />
      </DialogWrapper>
      {!EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id) && (
        <ProductDetailsModal
          open={cartPopUp}
          close={() => {
            setCartPopUp(false);
          }}
          photo={photo}
        />
      )}
    </div>
  );
};

export default SelectedImage;
