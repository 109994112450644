import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  checkAddressDetails,
  completeRazorSingle,
  completeShopping,
  couponOnOrder,
  createSingleOrder,
  getCartCount,
} from "../../apis/cart";
import { digitalImageDownload } from "../../apis/download";
import ColouredBulletIcon from "../../assets/icons/ColouredBulletIcon";
import LeftArrow from "../../assets/icons/LeftArrow";
import { ThemeContext } from "../../components/App";
import AddAddress from "../../components/common/AddAddress";
import AddAddressModal from "../../components/common/AddAddressModal";
import Button from "../../components/common/Button";
import OrderSummery from "../../components/common/OrderSummery";
import StaticLogo from "../../components/common/StaticLogo";
import Text from "../../components/common/Text";
import {
  BMS_COUPON_CODE,
  BMS_PHOTOGRAPHER_ID,
  CHECKOUT_ADDRESS_DISABLED,
  ENVIRONMENT,
  HIGH_RES_DOMAIN,
  INDIAN_CURRENCY,
  LOWER_RES_DOMAIN,
  PRICING_IN_DOLLAR,
  REDIRECT_ON_CART_USER,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
  STAGING_ENVIRONMENT_KEY,
} from "../../constants";
import { checkIsDeviceIOS, fireSingleDownloadGaEvent } from "../../helper";
import { useCart } from "../../Store/cart";
import { useEvent } from "../../Store/event";
import { useUser } from "../../Store/user";
import Loading from "../Loading";

// const stripePromise = loadStripe('pk_test_51IRw4HIFp4p7WTqbA0aZRjJSNVc6gtCVleVouWgG4EstFuHFAgBk5rBJNq4BRU0uATjuTSvoaWGTNUGxYNGbKbnD00U3q5V6dO');

function CheckOut() {
  let { user } = useUser();
  let {
    setCartCnt,
    cartList,
    setCartList,
    cartPayment,
    digitalCartItem,
    setDigitalCartItem,
  } = useCart();
  let eventDetails = useEvent().response;
  const Razorpay = useRazorpay();
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  const isIosDevice = useMemo(checkIsDeviceIOS, []);

  const emailFieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
    eventDetails.photographer.id
  );

  const checkIfAddressIsNeeded = () =>
    CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
      ? BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
        ? "lollapalooza"
        : "jio"
      : "";

  const getUserName = () =>
    CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
      ? user?.fname + " " + user?.lname
      : "";

  const getPhone = () =>
    CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
      ? emailFieldIsMobile
        ? user?.email
        : user?.phone
      : "";

  const shouldShowPayment = () =>
    CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id) ? true : false;

  const shouldUPIBeChecked = () =>
    CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id) ? true : false;

  const iconsColor = theme === "dark" ? "white" : "#222";
  const [isUPIVisible, setisUPIVisible] = useState(shouldUPIBeChecked());
  const [isCodChecked, setIsCodChecked] = useState(false);
  const [isAddAddressModalVisible, setisAddAddressModalVisible] =
    useState(false);
  const [userName, setuserName] = useState(getUserName());
  const [email, setemail] = useState(user.email ? user.email : "");
  const [phoneNumber, setphoneNumber] = useState(getPhone());
  const [address1, setaddress1] = useState(checkIfAddressIsNeeded());
  const [address2, setaddress2] = useState(checkIfAddressIsNeeded());
  const [country, setcountry] = useState(
    eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
      ? "USA"
      : "India"
  );
  const [city, setcity] = useState(checkIfAddressIsNeeded());
  const [state, setstate] = useState(checkIfAddressIsNeeded());
  const [PostalCode, setPostalCode] = useState(checkIfAddressIsNeeded());
  const [baddress1, setbaddress1] = useState(checkIfAddressIsNeeded());
  const [baddress2, setbaddress2] = useState(checkIfAddressIsNeeded());
  const [bcountry, setbcountry] = useState(
    eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
      ? "USA"
      : "India"
  );
  const [bcity, setbcity] = useState(checkIfAddressIsNeeded());
  const [bstate, setbstate] = useState(checkIfAddressIsNeeded());
  const [bPostalCode, setbPostalCode] = useState(checkIfAddressIsNeeded());
  const [addressList, setaddressList] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const stripe = useStripe();
  const elements = useElements();
  const [cardErrors, setCardErros] = useState("");
  const [trnxProcessing, setTrnxProcessing] = useState(0);
  const [loading, showLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState(false);
  const [cardDate, setCardDate] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);
  const [cardInner, setCardInner] = useState(false);
  const [cardExpiryInner, setCardExpiryInner] = useState(false);
  const [cardCvcInner, setCardCvcInner] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [payment, showPayment] = useState(shouldShowPayment());
  const [sameAddress, setSameAddress] = useState(false);
  const [downloadAlertShown, setDownloadAlertShown] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [shippingAmount, setShippingAmount] = useState();
  const [taxAmount, setTaxAmount] = useState();

  const areAllFieldsFilled =
    userName &&
    email &&
    address1 &&
    country &&
    city &&
    state &&
    PostalCode &&
    phoneNumber;
  const billingAddressFilled =
    baddress1 && bcountry && bcity && bstate && bPostalCode;

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: theme === "dark" ? "white" : "#222",
    fontFamily:
      "Poppins, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#87BBFD",
    },
  };

  useEffect(() => {
    if (!user.email) {
      if (
        eventDetails.photographer.domain &&
        user.accessLevel &&
        ENVIRONMENT !== STAGING_ENVIRONMENT_KEY
      ) {
        window.location.assign(
          `//${eventDetails.photographer.domain}/${
            user.accessLevel ?? "facerec"
          }/${eventDetails.data.slug}`
        );
      } else
        navigate(
          user.accessLevel === "view"
            ? `/view/${eventDetails.data.slug}`
            : `/facerec/${eventDetails.data.slug}/results/${user.search_id}`
        );
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (
      (areAllFieldsFilled && sameAddress) ||
      (areAllFieldsFilled && !sameAddress && billingAddressFilled)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }

    if (sameAddress) {
      setbaddress1(address1);
      setbaddress2(address2);
      setbcountry(country);
      setbcity(city);
      setbstate(state);
      setbPostalCode(PostalCode);
    }
  }, [
    userName,
    email,
    address1,
    country,
    state,
    city,
    PostalCode,
    bPostalCode,
    baddress1,
    bcity,
    bcountry,
    bstate,
    phoneNumber,
    sameAddress,
  ]);

  const addaddress = () => {
    if (
      userName &&
      email &&
      address1 &&
      address2 &&
      country &&
      city &&
      state &&
      PostalCode
    ) {
      setaddressList([
        ...addressList,
        {
          userName,
          email,
          phoneNumber,
          address1,
          address2,
          country,
          city,
          PostalCode,
        },
      ]);
    } else {
      alert("please Fill All required Fields");
    }
  };

  const singleImageDownload = (photo) => {
    if (isIosDevice) {
      const downloadButtonClicked = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        fireSingleDownloadGaEvent(photo[0].images.ogImageName, user.id);
        a.href = photo[0].images.src.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN);
        a.target = "_blank";
        setTimeout(() => a.click(), 100);
        document.body.removeChild(a);
      };
      if (!downloadAlertShown) {
        Swal.fire({
          icon: "info",
          title: "",
          text: "A new window with the photo will open. Press and hold the photo for option to save.",
        }).then(function (data) {
          if (data.isConfirmed) {
            downloadButtonClicked();
            setDownloadAlertShown(true);
          }
        });
      } else downloadButtonClicked();
      return;
    }
    fetch(photo[0].images.src.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN), {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          fireSingleDownloadGaEvent(photo[0].images.ogImageName, user.id);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", photo[0].images.ogImageName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (event) => {
    showLoading(true);
    event.preventDefault();
    if (areAllFieldsFilled) {
      setCardErros("");

      if (elements == null) {
        showLoading(false);
        return;
      }

      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
        })
        .then((result) => {
          if (result.error) {
            showLoading(false);
            displayError(result);
            setTrnxProcessing(0);
          } else {
            setTrnxProcessing(1);
            cartPayment(
              userName,
              email,
              address1,
              address2,
              country,
              city,
              state,
              PostalCode,
              baddress1,
              baddress2,
              bcountry,
              bcity,
              bstate,
              bPostalCode,
              phoneNumber,
              eventDetails.data?.downloadPricing?.currency ===
                PRICING_IN_DOLLAR ||
                eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
                ? "usd"
                : "inr",
              shippingAmount
            )
              .then((response) => {
                stripe
                  .confirmCardPayment(response.response.resp.clientSecret, {
                    payment_method: {
                      card: elements.getElement(CardNumberElement),
                      billing_details: {
                        name: userName,
                      },
                    },
                  })
                  .then(async (result) => {
                    if (result.error) {
                      showLoading(false);
                      displayError(result);
                      setTrnxProcessing(0);
                    } else {
                      let downloadRequestId;
                      let orderId;
                      if (result.paymentIntent.status === "succeeded") {
                        // update order table api
                        setTrnxProcessing(2);
                        const completionResult = await completeShopping(
                          result.paymentIntent.status,
                          result.paymentIntent.payment_method_types,
                          result.paymentIntent.id,
                          result.paymentIntent.created,
                          result.paymentIntent.payment_method
                        );
                        if (completionResult.error === false) {
                          orderId = completionResult.response.orderId;
                          elements.getElement(CardNumberElement).clear();
                          elements.getElement(CardExpiryElement).clear();
                          elements.getElement(CardCvcElement).clear();
                          setCartList([]);
                          const cartIds = [];
                          digitalCartItem.forEach((el) =>
                            el?.items?.forEach((e) => cartIds?.push(e.id))
                          );
                          if (cartIds.length === 1) {
                            cartIds.length = [];
                            cartIds.push(digitalCartItem[0].items[0]);
                          }
                          setDigitalCartItem([]);
                          let cartC = await getCartCount(
                            eventDetails.data.id,
                            user.accessToken
                          );
                          if (!cartC.error) {
                            setCartCnt(cartC.response.data.cartCount);
                          }
                          setuserName("");
                          setemail("");
                          setphoneNumber("");
                          setaddress1("");
                          setaddress2("");
                          setcity("");
                          setstate("");
                          setPostalCode("");
                          if (cartIds.length > 1) {
                            const res = await digitalImageDownload(
                              eventDetails.data.id,
                              user.email,
                              cartIds,
                              user.accessToken,
                              orderId
                            );
                            window.Toast.fire({
                              icon: "success",
                              title: res.response.message
                                ? res.response.message
                                : "Download in process, please wait for it to complete you wil receive an email shortly.",
                            });
                            if (res.response.downloadRequestId) {
                              downloadRequestId =
                                res.response.downloadRequestId;
                              try {
                                const url =
                                  window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/downloadNow/" +
                                  eventDetails.data.slug +
                                  "/" +
                                  res.response.downloadRequestId;
                                window.open(url, "_blank");
                              } catch (error) {
                                console.log(
                                  "Issue while opening browser in new window",
                                  error
                                );
                              }
                            }
                          } else if (cartIds.length === 1) {
                            singleImageDownload(cartIds);
                          }
                          if (downloadRequestId) {
                            navigate(
                              `/complete-shopping/${eventDetails.data.slug}/${orderId}/${downloadRequestId}`
                            );
                          } else
                            navigate(
                              `/complete-shopping/${eventDetails.data.slug}`
                            );
                          showLoading(false);
                        } else {
                        }
                      }
                    }
                  });
              })
              .catch((error) => {
                console.error(error);
                showLoading(false);
                displayError(error);
                setTrnxProcessing(0);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          showLoading(false);
          setTrnxProcessing(0);
          displayError(error);
        });
    } else {
      alert("please Fill All required Fields");
    }
  };

  const discountCouponApplied = async () => {
    const orderIds = [];
    digitalCartItem.forEach((el) =>
      el?.items?.forEach((e) => orderIds?.push(e.id))
    );
    try {
      showLoading(true);
      const data = await couponOnOrder(
        eventDetails.data.id,
        userName,
        email,
        address1,
        address2,
        country,
        city,
        state,
        PostalCode,
        baddress1,
        baddress2,
        bcountry,
        bcity,
        bstate,
        bPostalCode,
        phoneNumber,
        orderIds,
        user.accessToken,
        couponCode
      );
      setCartList([]);
      const cartIds = [];
      digitalCartItem.forEach((el) =>
        el?.items?.forEach((e) => cartIds?.push(e.id))
      );
      if (cartIds.length === 1) {
        cartIds.length = [];
        cartIds.push(digitalCartItem[0].items[0]);
      }
      setDigitalCartItem([]);
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }
      setuserName("");
      setemail("");
      setphoneNumber("");
      setaddress1("");
      setaddress2("");
      setcity("");
      setstate("");
      setPostalCode("");
      if (cartIds.length > 1) {
        const res = await digitalImageDownload(
          eventDetails.data.id,
          user.email,
          cartIds,
          user.accessToken,
          data.data.cartOrderId
        );
        window.Toast.fire({
          icon: "success",
          title: res.response.message
            ? res.response.message
            : "Download in process, please wait for it to complete you wil receive an email shortly.",
        });
        if (res.response.downloadRequestId) {
          // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.response.dl.toString())
          try {
            const url =
              window.location.protocol +
              "//" +
              window.location.host +
              "/downloadNow/" +
              eventDetails.data.slug +
              "/" +
              res.response.downloadRequestId;
            window.open(url, "_blank").focus();
          } catch (error) {
            console.log("Issue while opening browser in new window", error);
          }
        }
      } else if (cartIds.length === 1) {
        singleImageDownload(cartIds);
      }
      navigate(`/complete-shopping/${eventDetails.data.slug}`, {
        state: { cod: false },
      });
    } catch (error) {
      showLoading(false);
      console.log(error);
    }
  };

  const upiPay = () => {
    if (BMS_COUPON_CODE.includes(couponCode)) {
      discountCouponApplied();
      return;
    }
    var orderIds = [];
    {
      cartList.map(({ id }) => {
        orderIds.push(id);
      });
    }
    digitalCartItem.forEach((el) =>
      el?.items?.forEach((e) => orderIds?.push(e.id))
    );
    setButtonDisabled(true);

    createSingleOrder(
      eventDetails.data.id,
      userName,
      email,
      address1,
      address2,
      country,
      city,
      state,
      PostalCode,
      baddress1,
      baddress2,
      bcountry,
      bcity,
      bstate,
      bPostalCode,
      phoneNumber,
      orderIds,
      user.accessToken,
      false,
      eventDetails.data?.downloadPricing?.currency === PRICING_IN_DOLLAR ||
        eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
        ? "usd"
        : "inr"
    )
      .then((x) => {
        showLoading(true);
        // console.log(x);
        let downloadRequestId;
        let orderId;
        const cartOrderId = x.response.cartOrderId;
        const options = {
          key: process.env.REACT_APP_API_RAZOR,
          order_id: x.response.order_id,
          amount: x.response.amount,
          name: REDIRECT_ON_CART_USER.includes(eventDetails.photographer.id)
            ? "Digital Downloads"
            : "Frame Purchase",
          modal: {
            ondismiss: function () {
              setButtonDisabled(false);
              showLoading(false);
            },
          },
          handler: (res) => {
            completeRazorSingle({ ...res }).then(async (x) => {
              orderId = x.response.orderId;
              setCartList([]);
              const cartIds = [];
              digitalCartItem.forEach((el) =>
                el?.items?.forEach((e) => cartIds?.push(e.id))
              );
              if (cartIds.length === 1) {
                cartIds.length = [];
                cartIds.push(digitalCartItem[0].items[0]);
              }
              setDigitalCartItem([]);
              let cartC = await getCartCount(
                eventDetails.data.id,
                user.accessToken
              );
              if (!cartC.error) {
                setCartCnt(cartC.response.data.cartCount);
              }
              setuserName("");
              setemail("");
              setphoneNumber("");
              setaddress1("");
              setaddress2("");
              setcity("");
              setstate("");
              setPostalCode("");
              if (cartIds.length > 1) {
                const res = await digitalImageDownload(
                  eventDetails.data.id,
                  user.email,
                  cartIds,
                  user.accessToken,
                  cartOrderId
                );
                window.Toast.fire({
                  icon: "success",
                  title: res.response.message
                    ? res.response.message
                    : "Download in process, please wait for it to complete you wil receive an email shortly.",
                });
                if (res.response.downloadRequestId) {
                  downloadRequestId = res.response.downloadRequestId;
                  try {
                    const url =
                      window.location.protocol +
                      "//" +
                      window.location.host +
                      "/downloadNow/" +
                      eventDetails.data.slug +
                      "/" +
                      res.response.downloadRequestId;
                    window.open(url, "_blank").focus();
                  } catch (error) {
                    console.log(
                      "Issue while opening browser in new window",
                      error
                    );
                  }
                }
              } else if (cartIds.length === 1) {
                singleImageDownload(cartIds);
              }
              if (downloadRequestId) {
                navigate(
                  `/complete-shopping/${eventDetails.data.slug}/${orderId}/${downloadRequestId}`
                );
              } else
                navigate(`/complete-shopping/${eventDetails.data.slug}`, {
                  state: { cod: false },
                });
            });
          },
          prefill: {
            name: user.fname + " " + user.lname,
            email: user.email,
            contact: user.mobile,
          },
        };
        const rzpay = new Razorpay(options);

        rzpay.open();
      })
      .catch(showLoading(false));
  };

  function displayError({ error }) {
    if (error) {
      setTrnxProcessing(0);
      showLoading(false);
      setCardErros(error.message);
    } else {
      setCardErros("");
    }
  }

  const handleCardDetailsChange = (event) => {
    switch (event.elementType) {
      case "cardNumber":
        setCardInner(true);
        event.complete ? setCardNumber(true) : setCardNumber(false);
        break;
      case "cardExpiry":
        setCardExpiryInner(true);
        event.complete ? setCardDate(true) : setCardDate(false);
        break;
      case "cardCvc":
        setCardCvcInner(true);
        event.complete ? setCardCvc(true) : setCardCvc(false);
        break;
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;

  const togglePayment = async () => {
    const cartIds = [];
    {
      cartList.map(({ id }) => {
        cartIds.push(id);
      });
    }
    digitalCartItem?.forEach((el) =>
      el.items.forEach((e) => cartIds.push(e.id))
    );
    const postData = {
      addressOne: address1,
      country: country,
      email: email,
      name: userName,
      city: city,
      postalCode: PostalCode,
      mobile: phoneNumber,
      state: state,
      cartIds: cartIds,
    };

    if (
      eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY &&
      cartList.length
    ) {
      setButtonDisabled(true);
      try {
        const { data } = await checkAddressDetails(postData);
        setTaxAmount(+data[0].taxtotal.toFixed(2));
        setShippingAmount(+data[0].shiptotal.toFixed(2));
      } catch (error) {
        console.error(error);
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong while fetching price",
        });
        setButtonDisabled(false);
      }
    }
    showPayment(!payment);
  };

  const payCod = async () => {
    const orderIds = [];
    {
      cartList.map(({ id }) => {
        orderIds.push(id);
      });
    }
    digitalCartItem.forEach((el) =>
      el?.items?.forEach((e) => orderIds?.push(e.id))
    );
    setButtonDisabled(true);

    const data = await createSingleOrder(
      eventDetails.data.id,
      userName,
      email,
      address1,
      address2,
      country,
      city,
      state,
      PostalCode,
      baddress1,
      baddress2,
      bcountry,
      bcity,
      bstate,
      bPostalCode,
      phoneNumber,
      orderIds,
      user.accessToken,
      true
    );
    if (data.error) {
      window.Toast.fire({
        icon: "error",
        message: "Something went wrong",
      });
    } else {
      showLoading(true);
      setCartList([]);
      setDigitalCartItem([]);
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }
      navigate(`/complete-shopping/${eventDetails.data.slug}`, {
        state: { cod: true },
      });
    }
  };

  useEffect(() => {
    if (emailFieldIsMobile) setphoneNumber(user?.email);
  }, []);

  return (
    <div className="dark:text-white min-h-screen">
      <StaticLogo />
      {loading && <Loading />}
      {!loading && (
        <div
          className="flex space-x-5 items-center my-10 px-[24px] md:px-10 py-4"
          onClick={() => {
            navigate(-1);
          }}
        >
          <LeftArrow
            height={isMobile ? 16 : 24}
            width={isMobile ? 16 : 24}
            className="rotate-90 cursor-pointer"
            color={iconsColor}
          />
          <Text className="font-medium text-xl md:text-3xl cursor-pointer">
            Checkout
          </Text>
        </div>
      )}
      <div className="flex flex-col lg:flex-row justify-between px-[18px] md:px-10 py-4">
        <div className="w-full order-2 lg:order-1 lg:w-3/4">
          {/* ---------------- Address ----------------*/}
          {!loading && (
            <div className="mt-10 lg:mt-0 px-5">
              <div className="flex lg:space-x-5 items-start">
                <ColouredBulletIcon color="#22b7b9" className="mt-2" />
                <div className="lg:w-full lg:pr-8 md:ml-5">
                  <Text
                    as="div"
                    className="font-semibold text-base md:text-lg ml-5 mt-1 md:mt-0 md:ml-0"
                  >
                    {CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
                      ? "Contact Information"
                      : "Address"}
                  </Text>
                  <hr className="w-full text-black-gray my-4" />
                  {payment ? (
                    <>
                      {CHECKOUT_ADDRESS_DISABLED.includes(
                        eventDetails.data.id
                      ) ? (
                        <></>
                      ) : (
                        <Text className="font-medium text-sm text-xl">
                          Contact Information
                        </Text>
                      )}
                      <div className="mt-6 md:w-72">
                        <div className="flex items-center">
                          <label className="text-sm md:ml-1">{userName}</label>
                          <div
                            className="ml-6 text-sm font-semibold underline text-cyan cursor-pointer"
                            onClick={togglePayment}
                          >
                            Edit
                          </div>
                        </div>
                        <div className="text-sm md:ml-1">
                          {CHECKOUT_ADDRESS_DISABLED.includes(
                            eventDetails.data.id
                          )
                            ? `Phone number :-  ${phoneNumber}`
                            : `${address1},${city} - ${PostalCode}, ${state}
                          Phone number:- ${phoneNumber}`}
                        </div>
                      </div>
                    </>
                  ) : (
                    <AddAddress
                      userName={userName}
                      setuserName={setuserName}
                      email={email}
                      setemail={setemail}
                      phoneNumber={phoneNumber}
                      setphoneNumber={setphoneNumber}
                      address1={address1}
                      setaddress1={setaddress1}
                      address2={address2}
                      setaddress2={setaddress2}
                      country={country}
                      setcountry={setcountry}
                      city={city}
                      setcity={setcity}
                      state={state}
                      setstate={setstate}
                      PostalCode={PostalCode}
                      setPostalCode={setPostalCode}
                      baddress1={baddress1}
                      setbaddress1={setbaddress1}
                      baddress2={baddress2}
                      setbaddress2={setbaddress2}
                      bcountry={bcountry}
                      setbcountry={setbcountry}
                      bcity={bcity}
                      setbcity={setbcity}
                      bstate={bstate}
                      setbstate={setbstate}
                      bPostalCode={bPostalCode}
                      setbPostalCode={setbPostalCode}
                      setSameAddress={setSameAddress}
                      addaddress={addaddress}
                    />
                  )}

                  {isAddAddressModalVisible && (
                    <AddAddressModal
                      userName={userName}
                      setuserName={setuserName}
                      email={email}
                      setemail={setemail}
                      phoneNumber={phoneNumber}
                      setphoneNumber={setphoneNumber}
                      address1={address1}
                      setaddress1={setaddress1}
                      address2={address2}
                      setaddress2={setaddress2}
                      country={country}
                      setcountry={setcountry}
                      city={city}
                      setcity={setcity}
                      PostalCode={PostalCode}
                      setPostalCode={setPostalCode}
                      addaddress={addaddress}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {!payment && (
            <div className="mt-6 text-center">
              <Button
                className="mt-5"
                text="Submit Address"
                disabled={buttonDisabled}
                onClick={togglePayment}
              />
            </div>
          )}
          {/* ---------------- Payment ----------------*/}
          {payment && (
            <div
              className={`mt-20 px-5 ${
                loading ? "absolute top-0 opacity-0" : ""
              }`}
            >
              <div className="mt-20 md:px-5">
                <div className="flex space-x-5 items-start">
                  <ColouredBulletIcon color="#22b7b9" className="mt-2" />

                  <div className="w-full lg:pr-14 ml-5">
                    <Text className="font-semibold text-lg">Payment</Text>
                    <hr className="w-full text-black-gray my-4" />
                    {country == "India" &&
                      eventDetails.data?.downloadPricing?.currency !==
                        PRICING_IN_DOLLAR &&
                      eventDetails.photographer.printStoreCurrency ===
                        INDIAN_CURRENCY && (
                        <div className="flex items-center my-6">
                          <input
                            id="radio-1"
                            type="radio"
                            checked={isUPIVisible}
                            className="w-4 h-4 accent-cyan-600"
                            onChange={() => {
                              setisUPIVisible(true);
                              setIsCodChecked(false);
                            }}
                          />
                          <label className="ml-3 text-sm">
                            {CHECKOUT_ADDRESS_DISABLED.includes(
                              eventDetails.data.id
                            )
                              ? "UPI / Cards "
                              : "UPI"}
                          </label>
                        </div>
                      )}
                    {CHECKOUT_ADDRESS_DISABLED.includes(
                      eventDetails.data.id
                    ) ? (
                      <></>
                    ) : (
                      <div className="flex items-center">
                        <input
                          id="radio-1"
                          type="radio"
                          className="w-4 h-4 accent-cyan-600"
                          checked={!isUPIVisible && !isCodChecked}
                          onChange={() => {
                            setisUPIVisible(false);
                            setIsCodChecked(false);
                          }}
                        />
                        <label className="ml-3 text-sm">
                          Debit or credit card
                        </label>
                      </div>
                    )}
                    {/* COD OPTION */}
                    {/* {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                      eventDetails.data.id
                    ) ? (
                      <div className="flex items-center my-6">
                        <input
                          id="payAtKiosk"
                          type="radio"
                          className="w-4 h-4 accent-cyan-600"
                          checked={!isUPIVisible && isCodChecked}
                          onChange={() => {
                            setisUPIVisible(false);
                            setIsCodChecked(true);
                          }}
                        />
                        <label htmlFor="payAtKiosk" className="ml-3 text-sm">
                          Pay at kiosk
                        </label>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {!isUPIVisible && !isCodChecked && (
                      <>
                        {/* <div className='mt-5 space-y-10'>
                                            <div className='flex flex-col lg:flex-row lg:space-x-16'>
                                                <InputText label="Card number" className='w-full lg:w-[350px]' />
                                                <InputText label="Name on card" className='mt-6 lg:mt-0 w-full lg:w-[350px]' />
                                            </div>
                                            <div className='flex space-x-10 items-center'>
                                                <div>
                                                    <Text className="mb-1 ml-1 text-sm dark:text-gray">Expiry date*</Text>
                                                    <div className='flex space-x-3'>
                                                        <InputText islabel={false} placeholder="MM" className="w-11 " textcenter={true} />
                                                        <InputText islabel={false} placeholder="YYYY" className="w-16" textcenter={true} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <InputText label="CVV" className="w-28" />
                                                </div>
                                            </div>
                                        </div>

                                        <Button className="flex lg:mx-0 mx-auto w-36 justify-center px-12 my-10 text-base cursor-pointer dark:text-stone-400 text-stone-600" disabled={true} text="Pay" /> */}

                        <div className="flex flex-wrap justify-between items-start mt-5">
                          <div className="flex flex-col w-full lg:w-[40%]">
                            <label className="text-xs md:text-sm justify-between lg:mt-0 mb-4">
                              Card Number *
                            </label>
                            <div
                              className={`rounded-xl bg-transparent px-[16px] py-[10px] input-shadow-fix shadow-light-input dark:shadow-dark-input outline-none text-dark-gray1 dark:text-light-gray mt-1 ${
                                !cardInner
                                  ? "shadow-light-input dark:shadow-dark-input"
                                  : "shadow-inner-all dark:shadow-dark-inner-all"
                              } `}
                            >
                              <CardNumberElement
                                options={{
                                  style: {
                                    base: inputStyle,
                                  },
                                }}
                                onChange={handleCardDetailsChange}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col w-full lg:w-[20%]">
                            <label className="text-xs md:text-sm justify-between mt-6 lg:mt-0 mb-4">
                              Expiry Date *
                            </label>
                            <div
                              className={`w-full rounded-xl bg-transparent px-[16px] py-[10px] input-shadow-fix shadow-light-input dark:shadow-dark-input outline-none text-dark-gray1 dark:text-light-gray mt-1 ${
                                !cardExpiryInner
                                  ? "shadow-light-input dark:shadow-dark-input"
                                  : "shadow-inner-all dark:shadow-dark-inner-all"
                              } `}
                            >
                              <CardExpiryElement
                                options={{
                                  style: {
                                    base: inputStyle,
                                  },
                                }}
                                onChange={handleCardDetailsChange}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col w-full lg:w-[20%]">
                            <label className="text-xs md:text-sm justify-between mt-6 lg:mt-0 mb-4">
                              CVC *
                            </label>
                            <div
                              className={`w-full rounded-xl bg-transparent px-[16px] py-[10px] input-shadow-fix shadow-light-input dark:shadow-dark-input outline-none text-dark-gray1 dark:text-light-gray mt-1 ${
                                !cardCvcInner
                                  ? "shadow-light-input dark:shadow-dark-input"
                                  : "shadow-inner-all dark:shadow-dark-inner-all"
                              } `}
                            >
                              <CardCvcElement
                                options={{
                                  style: {
                                    base: inputStyle,
                                  },
                                }}
                                onChange={handleCardDetailsChange}
                              />
                            </div>
                          </div>
                          {cardErrors && (
                            <div className="w-full text-sm mt-3 text-red-600">
                              {cardErrors}
                            </div>
                          )}
                        </div>
                        {/* <div className='mt-6 text-center'>
                                                {trnxProcessing==0 &&
                                                    <Button className='lg:mx-0 mx-auto w-36 justify-center px-12 my-10 text-base cursor-pointer' disabled={areAllFieldsFilled && cardNumber && cardDate && cardCvc ? false:true} text="Pay" onClick={handleSubmit} />
                                                }
                                                {trnxProcessing==1 ? <Button className='order-1 lg:order-2 cursor-none text-base' text="Payment Processsing please Wait"/>: trnxProcessing==2? <Button className='order-1 lg:order-2 cursor-none text-base' text="Payment Complete"/>:''}
                                            </div> */}
                      </>
                    )}
                    {isUPIVisible && (
                      <>
                        <div className="mt-5">
                          {/* <InputText label="UPI ID" className='w-full lg:w-96' /> */}
                        </div>
                        {/* <Button
                                            disabled={buttonDisabled}
                                            onClick={upiPay}
                                            text={"Pay"}
                                            className={"mt-5 text-sm flex items-center justify-center"}
                                        /> */}
                      </>
                    )}
                    {isUPIVisible ? (
                      <Button
                        disabled={buttonDisabled}
                        onClick={upiPay}
                        text={"Pay"}
                        className={
                          "mt-5 text-sm flex items-center justify-center w-full md:w-fit"
                        }
                      />
                    ) : isCodChecked ? (
                      <Button
                        disabled={buttonDisabled}
                        onClick={payCod}
                        text={"Pay"}
                        className={
                          "mt-5 text-sm flex items-center justify-center w-full md:w-fit"
                        }
                      />
                    ) : (
                      <div className="mt-6 text-center">
                        {trnxProcessing == 0 && (
                          <Button
                            className="lg:mx-0 mx-auto justify-center px-12 my-10 text-base cursor-pointer w-full md:w-fit"
                            disabled={
                              areAllFieldsFilled &&
                              cardNumber &&
                              cardDate &&
                              cardCvc
                                ? false
                                : true
                            }
                            text="Pay"
                            onClick={handleSubmit}
                          />
                        )}
                        {trnxProcessing == 1 ? (
                          <Button
                            className="order-1 lg:order-2 cursor-none text-base w-full md:w-fit"
                            text="Payment Processsing please Wait"
                          />
                        ) : trnxProcessing == 2 ? (
                          <Button
                            className="order-1 lg:order-2 cursor-none text-base w-full md:w-fit"
                            text="Payment Complete"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!loading && (
          <div className="w-full lg:w-1/4 order-1 lg:order-2">
            <OrderSummery
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              shippingAmount={shippingAmount}
              taxAmount={taxAmount}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckOut;
