import React, { useEffect, useState } from "react";

import { getEventDetails, postVectorInit } from "../apis/events";
import {
  PRVACY_POLICY_ROUTE_NAME,
  VECTORIZE_CALL_EVERY_TIME,
  VECTORIZE_ON_NON_UPLOAD,
} from "../constants";

const Event = React.createContext();
Event.displayName = "Event";

export const useEvent = () => React.useContext(Event);

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState({ response: {}, error: false });

  useEffect(async () => {
    if (window.location.pathname !== PRVACY_POLICY_ROUTE_NAME) {
      try {
        let eventDetails = await getEventDetails();
        if (!eventDetails.error) {
          setEvent(eventDetails);
          if (
            VECTORIZE_CALL_EVERY_TIME.includes(
              eventDetails.response.photographer.id
            )
          ) {
            postVectorInit(eventDetails.response.data.awsEventName);
          }
          // if (
          //   VECTORIZE_ON_NON_UPLOAD.includes(
          //     eventDetails.response.photographer.id
          //   ) &&
          //   !eventDetails.response.data.isUploading
          // ) {
          //   postVectorInit(eventDetails.response.data.awsEventName);
          // }
        } else {
          setEvent({ response: eventDetails, error: true });
        }
      } catch (e) {
        console.log(e);
        setEvent({ response: e, error: true });
      }
    }
  }, [localStorage.getItem("access_token")]);

  return <Event.Provider value={event}>{children}</Event.Provider>;
};
