import Swal from "sweetalert2";

export const PRVACY_POLICY_ROUTE_NAME = "/privacy-policy";

export const SUPPORT_POLICY_ROUTE_NAME = "/support";

export const MOBILE_REGEX = /^([+]\d{2})?\d{10}$/;
export const EMIAL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const MY_PHOTOS = {
  slug: "my-photos",
  name: "My photos",
};

export const SAVE_FACE_REC_INFO = "saveFaceRecInfo";

export const GUEST_UPLOAD_SLUG = "guest-uploads";

export const FACE_SEARCH_NO_RESULT = {
  icon: "warning",
  text: "Nice smile! We are yet to upload your images!! Once we do, you will get them on your email id",
};

export const FACE_SEARCH_NO_RESULT_CUSTOM = {
  72768: {
    icon: "warning",
    text: "Hey there! We are working on this and you will receive your pictures soon",
  },
};

export const EVENT_WHERE_DROPBOX_NEEDED = [
  16931, 39558, 39592, 39593, 46812, 53479, 60942, 62775, 63091, 70814, 70831,
  114728, 105640, 105639, 105622, 139692, 139694, 140850, 151969, 159103,
];

export const PHOTOGRAPHER_WITH_DIRECT_FACE_REC = [4441];

/** Photographer id :- 72768 info@gonautika.com */
export const PHOTOGRAPHER_WITH_FACE_REC_ACCESS = [72768];

export const EVENT_WITH_DIRECT_FACE_REC = [151747, 152059];

export const EVENT_WITH_SPECIAL_DIGITAL_PURCHASE = [25230, 21130];

/** Event ID :- 28778 test event. 25230 - Jio hamley event. 28575 :- BMS. 21130:- JIO TEST EVENT , 8693:- Algomage event , 30128:-Lollapalooza event , 29635:- Imaging USA , 31080:- Lollapalooza test , 34863 :- WPPI */
export const EVENT_WHERE_PIN_NOT_REQUIRED = [
  25230, 21130, 28578, 28778, 8693, 30128, 29635, 31080, 34863,
];

export const PIN_WHERE_PIN_IS_HARD_CODED = {
  29635: 5733,
  30128: 9045,
  8693: 9417,
  28778: 6436,
  28578: 9725,
  25230: 6868,
  31080: 8950,
  34863: 7784,
  151747: 2178,
  152059: 8705,
};

export const EMAIL_WHERE_DIRECT_FACE_REC = {
  151747: "bhuvanchawlaphotography.com",
  152059: "sunpune@gmail.com",
  72768: "info@gonautika.com",
};

export const EVENT_WHERE_GUEST_UPLOAD_NOT_REQUIRED = [
  25230, 21130, 28578, 28778, 30128,
];

export const EVENT_WHERE_PHONE_IS_REQUIRED = [
  25230, 21130, 28578, 28778, 30128, 31080,
];

/** Photographer where face search phone is required.  17290:-slctexperiences@gmail.com  , 69110:- aj@photoartist.in*/
export const PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED = [17290, 64390, 69110];

/** Mail not needed from the drawer */
export const EVENT_WHERE_MAIL_NOT_NEEDED = [30128];

export const BMS_PHOTOGRAPHER_ID = [6536];

export const CHECKOUT_ADDRESS_DISABLED = [30128, 31080, 25230, 21130];

export const PRINT_IMAGE_SWAL_OBJECT = (theme, fileUrl) => {
  const iconColor = theme === "dark" ? "white" : "#222";
  const bgColor = theme === "dark" ? "#1A1A1A" : "white";

  let textClass = "";

  if (theme !== "dark") {
    textClass = "light-text-dark-gray-1-swal";
  } else textClass = "dark-text-light-gray-1-swal";

  return {
    iconHtml: `<img src=${fileUrl} style="
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 70%), -4px -4px 10px 0 rgb(58 58 58 / 50%), -1px -1px 0 0 hsl(0deg 0% 100% / 5%);
" />`,
    customClass: {
      icon: "!border-none !w-[80px] md:!w-[120px]",
      cancelButton: textClass,
      title: textClass,
      htmlContainer: textClass,
    },
    title: "Great news!",
    html: `
      <div style="text-align: left">
        <p style="margin-bottom: 10px">
          SMARTprints are live for this event so you can print beautiful studio
          quality prints right from your phone!
        </p>
        <div  style="font-size: 14px">
        <p style="margin-bottom: 10px">How to print:</p>
        <ol style="list-style-type: decimal;list-style-position: inside;">
          <li>Confirm you’d like a 4x6” print of the image above.</li>
          <li>
            Head over to the SMARTprint station and pick up your print.
          </li>
        </ol>
        </div>
      </div>`,
    showCancelButton: true,
    confirmButtonText: "Send to SMARTprinter",
    background: bgColor,
  };
};

export const VIEW_SEARCH_ID = "search_id";

export const CART_DIGITAL_STORE = "digitalStore";

export const CART_FRAME_STORE = "frameStore";

export const FRAME_DIGITAL = "digital";

export const JIO_PRINT_WITH_FRAME = 199;

export const JIO_PRINT_ONLY = 99;

export const FRAME_TYPE_JIO_PHYSICAL_COPY = "frameless";

export const FRAME_TYPE_JIO_EVENT = "jioFrame";

export const REFRESH_LOCAL_STORAGE = "refreshDone";

export const PRE_REGISTRATION_MESSAGE =
  "You have been registered! As soon as the images from the event are ready, you would receive an email";

export const SUBDOMAIN_TO_NAVIGATE = "bookmyshow";

export const DOMAIN_TO_NAVIGATE = "memories-lollaindia.bookmyshow.com";

export const BMS_COUPON_CODE = ["CELEBRITY100"];

export const DOWNLOAD_ORIGINAL_IMAGE = "original";

export const LOWER_RES_DOMAIN = process.env.REACT_APP_RENDERER_CDN;

export const HIGH_RES_DOMAIN = process.env.REACT_APP_OG_CDN;

export const REDIRECT_ON_CART_USER = [6536, 992];

export const RAJPAL_PHOTOGRAPHER_ID = [992];

export const RAJPAL_DOMAIN = "photos.rajpalproductions.com";

export const RAJPAL_SUBDOMAIN = "rajpalproductions";

export const PRICING_IN_DOLLAR = "dollar";

export const COUNTRY_INDIA = "India";

export const EVENT_WITH_FACE_SEARCH_FOLDER = [43861, 43860, 43254];

export const PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER = [7290];

/** Photographer id for whom , add all items to cart button would be present on face search page for digital downloads. 72- hello@picsniff.com  992 - Rajpal studios */
export const PHOTOGRAPHER_WITH_ALL_DIGITAL_DOWNLOAD = [72, 992];
/** Photographer id for whom powered by algomage won't come. 2579 :- Sam 16076 :-zo@tvgm.ae  17290 :- slctexperiences@gmail.com 64164:- shivam@conferencesinternational.in */
export const PHOTOGRAPHER_WITH_NO_BOTTOM_BRANDING = [2579, 16076, 17290, 64164];

export const LOCALSTORAGE_ACCESS_TOKEN = "access_token";

export const INDIAN_CURRENCY = "inr";

export const MILLER_FRAME_SIZE_INCHES = {
  "3.5x5": "3.75X5.25",
  "4x5": "4.25X5.25",
  "4x6": "4.25X6.25",
  "5x5": "5.25X5.25",
  "5x7": "5.25X7.25",
  "6x9": "6.25X9.25",
  "8x8": "8.25X8.25",
  "8x10": "8.25X10.25",
  "8x12": "8.25X12.25",
  "10x10": "10.25X10.25",
  "12x12": "12.25X12.25",
  "10x15": "10.25X15.25",
  "5x30": "5.25X30.25",
  "11x14": "11.25X14.25",
  "10x20": "10.25X20.25",
  "12x18": "12.25X18.25",
  "12x24": "12.25X24.25",
  "10x30": "10.25X30.25",
  "16x20": "16.25X20.25",
  "16x24": "16.25X24.25",
  "20x20": "20.25X20.25",
  "20x24": "20.25X24.25",
  "20x30": "20.25X30.25",
  "24x36": "24.25X36.25",
};

export const INTERNAL_PRODUCT_KEY = {
  Prints: "print",
};

export const MAX_FACE_REG_REACHED_POPUP = () =>
  Swal.fire({
    title: `Registration Limit Reached for this event.`,
    icon: "error",
    text: `Please contact your photographer.`,
    confirmButtonText: "Ok",
  });

export const MAX_GUEST_LIMIT_REACH_BE_MESSAGE = "Guest Limit Reach";

export const MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE =
  "Maximum Face Search limit reached";

export const IMAGE_LIMIT_REACHED = "Limit reached";

export const MAX_SUB_EVENT_IMAGE_REACHED_ERROR =
  "Maximum Images Per Folder Limit Reach";

export const LOGIN_BE_MESSAGE_FOR_LIMIT = "Maximum Allowed Guest limit reached";

export const IMAGE_OG_SUBDOMAIN = process.env.REACT_APP_OG_SUBDOMAIN;
export const IMAGE_RENDERER_SUBDOMAIN =
  process.env.REACT_APP_RENDERER_SUBDOMAIN;

/** User ID  for which showing mobile field instead of email  3226 :- Contact@gennextstudio.in 764:- sunpune@gmail.com */
export const SHOW_MOBILE_INSTEAD_OF_EMAIL = [3226, 764];

export const MOBILE_REGEX_TO_CHECK_DIGITS = /^(?:\D*\d){9,}.*$/;

export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const STAGING_ENVIRONMENT_KEY = "staging";

/** Upload size in KB */
export const MAX_UPLOAD_IMAGE_SIZE = 6000000;

export const CORPORATE_TAG = "Corporate";

export const VECTORIZE_CALL_EVERY_TIME = [16500];
/**Photographer id :- 71 :- Info@amouraffairs.in , 764 :- sunpune@gmail.com ,
 * 35471 :-aelisaphotos@gmail.com,
 * 2138 :-jeetmohnaniphotography@gmail.com ,
 * 38542 :- dolly.algomage@gmail.com ,
 * 20317 :-  sanyograjephotography@gmail.com ,
 * 16098 picturebaskett@gmail.com ,
 * 4844  :-pavankagrawaal@gmail.com,
 * 32730:- shree10patil@gmail.com ,
 * 6261:- agwlmohit11@gmail.com,
 * 39637:- Artmancappturez@gmail.com,
 * 56042 :- lagnaavsar@gmail.com
 * 16426:- rahuldarakcaptures@gmail.com
 * 21704:- pccreations07@gmail.com
 * 12622:- fotointernational@gmail.com
 * 18969:- rohitstudiosphotography27@gmail.com
 * 3287 :- shadowgraphystudio1@gmail.com
 * 21643 :- seethroughet@gmail.com
 * 1768 :- pikselperfektphotography@gmail.com
 * 72762:- theweddingklicks7@gmail.com
 * 16069 :- Madjamtales@gmail.com
 * */

export const VECTORIZE_ON_NON_UPLOAD = [
  71, 764, 35471, 2138, 38542, 20317, 16098, 4844, 32730, 68255, 39637, 56042,
  16426, 21704, 12622, 18969, 3287, 21643, 1768, 72762, 16069,
];
